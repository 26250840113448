import React from 'react';
import DisplayWPPostsList from './services/DisplayWPPostsList';  // Adjust path accordingly

const App = () => {
  return (
      <div className="App">
          <div>
              <img src="https://wp-flap-flap.ferociousbutterfly.com/img/flap-flap-headless.svg" alt="flap flap" className="topLogo"/></div>
        <DisplayWPPostsList />
      </div>
  );
};

export default App;