import React from 'react';
import useFetchPosts from './FetchWPPosts';
import './DisplayWPPostsList.css';

const DisplayWPPostsList = () => {
    const { posts, loading } = useFetchPosts();

    if (loading) return <div>Loading...</div>;

    return (
        <div className='WpPostListContainer'>
                {posts.map(post => (
                <div key={post.id} id={`post-${post.id}`} className='WpPost'>
                    <h2>{post.title.rendered}</h2>
                    <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
                </div>
            ))}

        </div>
    );
 
};

export default DisplayWPPostsList;