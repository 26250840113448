import axios from 'axios';
import { useEffect, useState } from 'react';

const FetchWPPosts = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('https://wp-flap-flap.ferociousbutterfly.com/wp-json/wp/v2/posts')
            .then(response => {
                setPosts(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("There was an error fetching the posts!", error);
                setLoading(false);
            });
    }, []);

    return { posts, loading };
};

export default FetchWPPosts;